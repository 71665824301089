import React, {Component} from "react";
import {useState, useEffect} from 'react'
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';



export default function BasicModal(props) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
      setOpen(true);
  }

  const handleClose = () => {
      props.setIsViewingImage(false)
      setOpen(false);

  }

  useEffect(()=>{
    if(props.isViewingImage){
        handleOpen()
        
    }
  },[props.isViewingImage])




  return (
      <Modal
        open={open}
        aria-labelledby="Image View"
        aria-describedby="modal-modal-description"
      >
        <div className="viewImg">
          <IconButton className="closeIcon" color="secondary" onClick={()=>handleClose()}>
            <CloseIcon/>
          </IconButton>
          <div className="fademask"></div>
          {(props.data && props.type==="profile") &&(
            <>
              <img className="bgimg" src={"https://api.geniux.co.za/geniuxmusic/api/media/avatars/original/?file_name="+props.data.user_avatar}/>
              <img className="img profile" src={"https://api.geniux.co.za/geniuxmusic/api/media/avatars/original/?file_name="+props.data.user_avatar}/>
            </>
              
          )}
          {props.data && props.type==="artcover" &&(
            props.data.song.artcover.length>2?
            <>
              <img className="bgimg"  src={"https://api.geniux.co.za/geniuxmusic/api/media/arts/original/?file_name="+props.data.song.artcover}/>
              <img className="img"  src={"https://api.geniux.co.za/geniuxmusic/api/media/arts/original/?file_name="+props.data.song.artcover}/>
            </>
            :
            <>
              <img className="bgimg"  src="/assets/images/defaultsongart.jpg"/>
              <img className="img"  src="/assets/images/defaultsongart.jpg"/>
            </>
          )}
          {props.data && props.type==="album_artcover" &&(
            props.data.results[0].album.artcover.length>2?
            <>
              <img className="bgimg"  src={"https://api.geniux.co.za/geniuxmusic/api/media/arts/original/?file_name="+props.data.results[0].album.artcover}/>
              <img className="img"  src={"https://api.geniux.co.za/geniuxmusic/api/media/arts/original/?file_name="+props.data.results[0].album.artcover}/>
            </>
            :
            <>
              <img className="bgimg"  src="/assets/images/defaultsongart.jpg"/>
              <img className="img"  src="/assets/images/defaultsongart.jpg"/>
            </>
          )}
          {props.data && props.type==="spotify-profile" &&(
            <>
              <img className="bgimg"  src={props.data.images[0].url}/>
              <img className="img profile"  src={props.data.images[0].url}/>
            </>
           
          )}
          {props.data && props.type==="spotify-artcover" &&(
            <>
              {(props.data.type==="album" && props.data.images.length>0 && props.data.images[0])?
                <>
                  <img className="bgimg"  src={props.data.images[0].url}/>
                  <img className="img"  src={props.data.images[0].url}/>
                </>:
                (props.data.type!=="album" && props.data.album.images.length>0 && props.data.album.images[0])?
                <>
                  <img className="bgimg"  src={props.data.album.images[0].url}/>
                  <img className="img"  src={props.data.album.images[0].url}/>
                </>:
                <>
                <img className="bgimg"  src="/assets/images/defaultsongart.jpg"/>
                <img className="img"  src="/assets/images/defaultsongart.jpg"/>
                </>
              }
            </>
           
          )}
        </div>
            
         
  
      </Modal>
  );
}
