import React, {Component} from "react";
import Button from '@mui/material/Button';
import {useState,useEffect,useContext} from 'react';
import {Link, useParams, useLocation} from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import IconButton from '@mui/material/IconButton';
import NavigationMenu from './MobileNavigation.js';
import Badge from '@mui/material/Badge';
import { UserStoreContext } from '../Contexts/UserStoreContext.js';
const MenuToggle = () => {
    const location = useLocation();
    const[innerWidth,setinnerWidth] = useState(window.innerWidth)
    useEffect(()=>{
        const updateInnerWidth = () => {
            setinnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateInnerWidth);
        return () => window.removeEventListener("resize", updateInnerWidth)
    },[])
    
    const {user_id} = useParams();

    //Check Login Credentials
    const {is_logged_in, user_store} = useContext(UserStoreContext)

    const [toggleMenu, setToggleMenu] = useState(false)

    const searchHint = () => {
        if(location.pathname.includes("/browse")){
            if(document.querySelector(".FilterPanel .searchbar input")!== null){
                document.querySelector('.FilterPanel .searchbar input').focus()
            }
        }
     };

    return (
        <>
            <Link to={"/browse?group=all"}>
                <IconButton className="icon" onClick={()=>searchHint()}>
                    <SearchIcon/>
                </IconButton>
            </Link>
           
            <IconButton className="icon" onClick={()=>setToggleMenu(true)}>
                {is_logged_in && user_store.unseen_notification>0?
                    <Badge badgeContent={user_store.unseen_notification} className="icon">
                    <MenuIcon />
                    </Badge>:
                    <MenuIcon />
                }
            </IconButton>
            {is_logged_in?
            <>
                {
                    user_id!=user_store.user_id?
                    <Link to={"/profile/"+user_store.user_id}>
                        <Button className="useraccount loggedin">
                            <div className="usericon">
                                {user_store.user_avatar.length>1?
                                    <img className="userpic" src={"https://api.geniux.co.za/geniuxmusic/api/media/avatars/smaller/?file_name="+user_store.user_avatar}  />
                                    :
                                    <p>{user_store.artist_name.substring(0, 1)}</p>
                                }
                            </div>
                            <div className="details">
                            <p>{user_store.artist_name}</p>
                            </div>
                        </Button>
                    </Link>:
                    <Link to={"/profile/manage/account"}>
                        <IconButton className="icon">
                            <ManageAccountsIcon />
                        </IconButton>
                    </Link>
                    
                }
                
                </>:
                <Link to="/login">
                    <Button className="useraccount notloggedin">
                        <div className="details">
                            <p>Login{innerWidth>992 &&("/Sign up")}</p>
                        </div>
                    </Button>
                </Link>
            }
           
           
            {toggleMenu && (
                <NavigationMenu toggleMenu={toggleMenu} setToggleMenu={setToggleMenu}/>   
            )}
            
        </>
    )
}

export default MenuToggle
