import {useState, useEffect, useContext} from 'react'
import Axios from 'axios';
import { UserStoreContext } from '../Contexts/UserStoreContext';
const useFetch = () => {
    const {is_logged_in, set_is_logged_in,set_user_store, user_store} = useContext(UserStoreContext)

    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(true);
    const[error, setError] = useState(null);


    useEffect(()=>{

        const url = 'https://api.geniux.co.za/geniuxmusic/api/validate_user.php';
        
        let cancel
        Axios({
            method: 'post',
            url: url,
            data: {
                myuserid: is_logged_in?user_store.user_id:"",
                mytoken: is_logged_in?user_store.user_token:"",
            },
            cancelToken: new Axios.CancelToken(c => cancel = c),

        })
        
        .then(response=>{
            if(response.data && response.data.length>0){
                // localStorage.setItem('user_store', JSON.stringify(...response.data))
                set_is_logged_in(true)
                set_user_store(...response.data)
            }else{
                localStorage.removeItem('user_store')
                set_is_logged_in(false)
                set_user_store(null)
            }
        
            
        })
        .catch(err=>{
            if(!Axios.isCancel(err)){
                setError('network_err');
            }
        })
        return () => cancel()
    },[is_logged_in]) 

        
 
    return <></>
}

export default useFetch
    