
import React, {Component} from "react";
import {useState, useEffect, useContext} from 'react'
import {Helmet} from "react-helmet";
import {Link, useNavigate, useParams} from "react-router-dom"; 
import { UserStoreContext } from '../../../../../Components/Contexts/UserStoreContext';

import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Button from '@mui/material/Button';
import useFetch from '../../../../../Components/APIs/FetchSongs';
import IsLoading from '../../../../../Components/isLoading.js';
import ShareUserExperience from '../../../../../Components/ShareUserExperience/Form.js';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import LinkSharpIcon from '@mui/icons-material/LinkSharp';
import { Share } from '@capacitor/share';
import { Clipboard } from '@capacitor/clipboard';
import NavigationMenuToggle from '../../../../../Components/Navigation/NavigationMenuToggle.js'

export default function MaxPlayer () {
    const navigate = useNavigate();

    const[innerWidth,setinnerWidth] = useState(window.innerWidth);
    const [pagescroll, setPageScroll] = useState(0);
    useEffect(()=>{
        window.scrollTo(0, 0)
        window.onscroll = () => {
            setPageScroll(window.pageYOffset)
        }
        const updateInnerWidth = () => {
            setinnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateInnerWidth);
        return () => window.removeEventListener("resize", updateInnerWidth)
    },[])
    const {song_id} = useParams();


    const [data, setData] = useState(null)
    const [Error,setError] = useState(null)
    const [request,setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    const send_request = ()=>{
        setRequest({
            "song_id": song_id
        })
    }
    useEffect(()=>{
        if(FetchedData){
            setData(FetchedData)
        }

    },[FetchedData])

   
    useEffect(()=>{
        setData(null)
        send_request()
    },[song_id])


  


    const[url, setUrl] = useState(null)
    const[caption, setCaption] = useState(null)
    
    useEffect(()=>{
        if(data){
            setUrl('https://music.geniux.co.za/playing/'+data.results[0].song.song_id+"/")
            setCaption('Check out the song: '+data.results[0].song.title+' on Geniux Music. ')
        }
    },[data])


     //copy link
     const [copySuccess, setCopySuccess] = useState(null);
     const  copyToClipboard=()=> {
         Clipboard.write({
             string: url
         });
         setCopySuccess(true);
         setTimeout(() => {
             setCopySuccess(null);
         }, [1000]);
     };

    const sharemore=()=>{
      
        Share.share({
            title: 'Song',
            text: caption,
            url: url,
            dialogTitle: 'Share with buddies',
        });
    }


   

    return (
    <>
        {data && data.total_results>0?
        <>
            <Helmet>
                <title>{"Upload Completed: "+data.results[0].song.title}</title>
                <meta property="og:title" content={"Upload Completed: "+data.results[0].song.title}/>
                <meta name="title" content={"Upload Completed: "+data.results[0].song.title}/>
                <meta name="description" content={data.results[0].song.description.length>1?data.results[0].song.description.length<90? data.results[0].song.description:data.results[0].song.description.substring(0, 90) + "...":"Check out this song on Geniux Music."} key="3"/>
                {data.results[0].song.artcover.length>2?
                <meta property="og:image" content={"https://api.geniux.co.za/geniuxmusic/api/media/avatars/smaller/?file_name="+data.results[0].song.artcover}/>
                :
                <meta property="og:image" content="/assets/images/geniuxbanner.jpeg"/>

                }
                
            </Helmet>
            {innerWidth<992 &&(
                <div className="navbar">
                    <div className="wrapper">
                        <div className="lft">
                            <IconButton className="icon" onClick={()=> navigate(-1)}>
                                <ArrowBackIcon />
                            </IconButton> 
                            <div className="titles">
                                <p>UPLOAD COMPLETED</p>
                            </div>
                        </div>
                        <div className="rgt">
                            <NavigationMenuToggle/>
                        </div>
                    </div>
                </div>
            )}
            <div className="mycontainer uploadcomplete">
                <div className="banner">
                    {data.results[0].song.artcover.length>1?
                        <img className="bgimg" src={"https://api.geniux.co.za/geniuxmusic/api/media/arts/medium/?file_name="+data.results[0].song.artcover}/>
                        :
                        <img className="bgimg" src="/assets/images/geniuxbanner.jpeg'"/>
                    }
                    <div className="fademask"></div>
                    <div className="wrapper">
                        <div className="lft">
                            {data.results[0].song.artcover.length>1?
                                <img className="artcover" src={"https://api.geniux.co.za/geniuxmusic/api/media/arts/medium/?file_name="+data.results[0].song.artcover}/>
                                :
                                <img className="artcover" src="/assets/images/defaultsongart.jpg"/>
                            }
                        </div>
                        <div className="rgt">
                            <Link to={'/playing/'+data.results[0].song.song_id}><Button>Play Song</Button></Link>
                        </div>
                    </div>
                </div>

                <div className="wrapper">
                    <div className="">
                        <div className="contain">
                                
                                <h2>Your song is ready to be heard</h2>

                                <div className="details">
                                    <p>Title: {data.results[0].song.title} </p>
                                    <p>Genre: {data.results[0].song.genre} </p>
                                    <p>Publisher: <Link href={`/profile/${data.results[0].user.user_id}`}>{data.results[0].user.artist_name}</Link></p>
                                    {(data.results[0].song.description!=="" && data.results[0].song.description.length>1)&&(
                                        <>
                                            <p>Description:</p>
                                            <p>{data.results[0].song.description}</p>
                                        </>
                                    )}
                                
                                    
                                </div>
                                
                                
                                <div className="row">
                                    <div className="col-12 col-lg-6">

                                        <div className="htitle"><div className="lft"><p>You may share with friends via</p></div></div>
                                        <div className="shareplatforms">
                                            <div className="row">
                                                <div className="col-6 col-lg-6">
                                                    <a href={innerWidth<992?"whatsapp://send?text="+caption+url:"https://api.whatsapp.com/send?text="+caption+url} data-action="share/whatsapp/share" target="_blank">
                                                        <Button color="secondary" className="card06" >
                                                            <div>
                                                                <WhatsAppIcon/>
                                                                <p>Whatsapp</p>
                                                            </div> 
                                                        </Button>   
                                                    </a>
                                                    
                                                </div>
                                                <div className="col-6 col-lg-6">
                                                    <a href={"https://www.facebook.com/sharer/sharer.php?u="+url} target="_blank">
                                                        <Button color="secondary" href={"https://www.facebook.com/sharer/sharer.php?u="+url} target="_blank" className="card06">
                                                            <div>
                                                                <FacebookIcon/>
                                                                <p>Facebook</p>   
                                                            </div>
                                                        </Button>
                                                    </a>
                                                </div>
                                                <div className="col-6 col-lg-6">
                                                    <Button color="secondary" className="card06" onClick={()=>copyToClipboard()}>
                                                        <div>
                                                            <LinkSharpIcon/>
                                                            <p>{copySuccess?"Link Copied":"Copy Link"}</p>   
                                                        </div>
                                                    </Button>
                                                </div>
                                                <div className="col-6 col-lg-6">
                                                    <Button color="secondary" className="card06"  onClick={()=>sharemore()}>
                                                        <div>
                                                            <MoreHorizIcon/>
                                                            <p>More</p>    
                                                        </div>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="htitle"><div className="lft"><p>Rate our platform</p></div></div>
                                        <p>Help us improve the platform by sharing your user experience</p>
                                        <ShareUserExperience/>
                                    </div>
                                </div>
                        

                        </div>
                    </div> 
                
            
                   
                </div>
            
            </div>  
        </>:
        data && data.total_results<1?
        <>
            <Helmet>
                <title>Song not found</title>
                <meta property="og:title" content="Song not found"/>
                <meta name="title" content="Song not found"/>
                <meta name="description" content="Song not found, link might be invalid or it's been removed"/>
                <meta property="og:image" content="/assets/images/geniuxbanner.jpeg"/>
            </Helmet>
            {innerWidth<992 &&(
            <>
                <div className="navbar">
                    <div className="wrapper">
                        <div className="lft">
                            <IconButton className="icon" onClick={()=>navigate(-1)}>
                                <ArrowBackIcon />
                            </IconButton> 
                            <div className="titles">
                                <p>UPLOAD COMPLETED</p>
                                {pagescroll> 80 &&(
                                    <p>{song_id}</p>
                                )}
                            </div>
                        </div>
                        <div className="rgt">
                        <NavigationMenuToggle/>
                        </div>  
                    </div>
                </div>
            </>
            ) }
            <div className="mycontainer uploadcomplete">
                <div className="wrapper">
                    <div className="no_data_msg">
                        <div className="container">
                            <div className="icon">
                                <img src="/assets/images/no_music_found.svg" />
                            </div>
                            <p>Song not found, link might be invalid or file is removed</p> 
                            <Button onClick={()=>send_request()}>Retry</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>:
        isLoading?
        <>
            {innerWidth<992 &&(
            <>
                <div className="navbar">
                    <div className="wrapper">
                        <div className="lft">
                            <IconButton className="icon" onClick={()=>navigate(-1)}>
                                <ArrowBackIcon />
                            </IconButton> 
                            <div className="titles">
                                <p>UPLOAD COMPLETED</p>
                                {pagescroll> 80 &&(
                                    <p>{song_id}</p>
                                )}
                            </div>
                        </div>
                        <div className="rgt">
                        <   NavigationMenuToggle/>
                        </div>  
                    </div>
                </div>
            </>
            ) }
            <div className="mycontainer uploadcomplete">
                <div className="wrapper">
                    <IsLoading/>
                </div>
            </div>
        </>:
        error==="network_err"?
        <>
            <Helmet>
                <title>No Internet</title>
                <meta property="og:title" content="No Internet"/>
                <meta name="title" content="No Internet"/>
                <meta name="description" content="Check your internet connectivity"/>
                <meta property="og:image" content="/assets/images/geniuxbanner.jpeg"/>
            </Helmet>
            {innerWidth<992 &&(
            <>
                <div className="navbar">
                    <div className="wrapper">
                        <div className="lft">
                            <IconButton className="icon" onClick={()=>navigate(-1)}>
                                <ArrowBackIcon />
                            </IconButton> 
                            <div className="titles">
                                <p>UPLOAD COMPLETED</p>
                                {pagescroll> 80 &&(
                                    <p>{song_id}</p>
                                )}
                            </div>
                        </div>
                        <div className="rgt">
                        <NavigationMenuToggle/>
                        </div>  
                    </div>
                </div>
            </>
            ) }
            <div className="mycontainer uploadcomplete">
                <div className="wrapper">
                    <div className="no_data_msg">
                        <div className="container">
                            <div className="icon">
                                <img src="/assets/images/no_music_found.svg" />
                            </div>
                            <p>Check your internet connectivity</p> 
                            <Button onClick={()=>send_request()}>Retry</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>:
        <></>

    }
    </>
    )
}


