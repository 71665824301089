import {useEffect, useState,useContext } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';

import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import Button from '@mui/material/Button';
import {PlayContext} from '../Contexts/PlayContext.js'
import {Link, useNavigate  }  from'react-router-dom'; 
import TimeAgo from 'timeago-react'; 
import Tooltip from '@mui/material/Tooltip';
import useFetch from '../APIs/LikeDislikeDownloadSong.js';
import LogginDialog from '../Dialogs/LogginDialog.js';
import CircularProgress from '@mui/material/CircularProgress';
import { UserStoreContext } from '../Contexts/UserStoreContext';
import ConvertNum from '../ConvertNumbers/useConvertLargeNum';
import { useGlobalAudioPlayer } from "react-use-audio-player"

const SongCard = (props) => {
    const navigate = useNavigate();

    const {is_logged_in, user_store} = useContext(UserStoreContext)
    
    const {PlayData,setPlayData,PlayStatus,setPlayIndex,setPlayList} = useContext(PlayContext)
    const { play, pause } = useGlobalAudioPlayer({ highRefreshRate: false })
   

    const [data, setData] = useState(null)
    useEffect(()=>{
        if(props.data){
            setData(props.data)
        }
    },[props.data]) 

    const PlaySong = () =>{
        if(PlayData && data.song.song_id===PlayData.data.song.song_id){
                if(PlayStatus==='playing'){
                    pause()
                }else{
                play()
                }  
        }else{
            setPlayData({"platform": "geniuxmusic",data});
        }
        if(props.Data && props.Data.length>0){
                setPlayList(props.Data)
                setPlayIndex(props.index)
        }
       
        
    }


    const [showLogIn, setShowLogIn] = useState(false);
    //Like, Dislike, Download
    const [request,setRequest] = useState(null);
    const {data:FetchedData,isLoading, error} = useFetch(request)


    useEffect(()=>{
        if(FetchedData && FetchedData.results.length>0){
            setData(FetchedData.results[0])
        }
        
    },[FetchedData])


 
    const LikeIt =() =>{
        if(is_logged_in && data){
            setRequest({
                'song_id':data.song.song_id,
                'action':'like',
            })

        }else{
      
            setShowLogIn(true)
       
        }
    
    }


  return (
    <>
        {data &&(
        <div className="card001">
                
            <div className="placeholder">
                {data.song.type=="album" &&(<div className="type"><p> <Link to={"/playing/"+data.song.song_id}>{data.song.album_title}</Link></p></div>)}
                
                {data.song.artcover.length>1?
                    <img className="artcover" src={"https://api.geniux.co.za/geniuxmusic/api/media/arts/medium/?file_name="+data.song.artcover}/>
                    :
                    <img className="artcover" src={"/assets/images/defaultsongart.jpg"}/>
                }
                <div className={PlayData&&PlayData.data.song.song_id===data.song.song_id?"playbtn active":"playbtn"}  onClick={()=>PlaySong()}>
                    <Button>
                        {PlayData&&PlayData.data.song.song_id===data.song.song_id && PlayStatus==='playing'?
                        <PauseIcon/>
                        :
                        PlayData&&PlayData.data.song.song_id===data.song.song_id && PlayStatus==='paused'?
                        <PlayArrowIcon/>
                        : 
                        <PlayArrowIcon/>
                        }
                    </Button>
                </div>
            </div>
            <div className="details">
                
          
                <div className="titles">
                    <Tooltip title={data.song.title} placement="top">
                        <p>
                            <Link to={"/playing/"+data.song.song_id}>
                                {data.song.title}
                            </Link>
                        </p>
                    </Tooltip>
                    <Tooltip title={"Published by "+data.user.artist_name +". Released date: "+data.song.date_added.substr(0,11)} placement="bottom">
                        <p>
                            <Link to={'/profile/'+data.user.user_id}>
                            {data.user.artist_name.length<15?
                                data.user.artist_name:
                                data.user.artist_name.substring(0, 15) + "..."
                            }
                            </Link><TimeAgo datetime={data.song.date_added} />
                        </p>
                    </Tooltip>
                   
                </div>
           
                <div className="stats">
                    
                    <Button className="myicon" color="secondary">
                        {data.song.played > 0 ? <PlayCircleFilledIcon className="active"/> : <PlayCircleFilledWhiteOutlinedIcon/>}<p><ConvertNum num = {data.song.plays}/></p>
                    </Button>
                    <Button className={data.song.liked>0?"myicon active":"myicon"}  color="secondary" onClick={()=>LikeIt()}>
                       {isLoading?<CircularProgress/>:<>{data.song.liked > 0 ? <FavoriteIcon className="active"/> : <FavoriteBorderIcon/>}<p><ConvertNum num = {data.song.likes}/></p> </>}
                    </Button>
                    <Button className="myicon" color="secondary" onClick={()=>navigate("/playing/"+data.song.song_id+"/#comments")}>
                        <ModeCommentOutlinedIcon/><p><ConvertNum num = {data.song.comments}/></p>
                    </Button>
                </div>
            </div>
        </div>
        
        )}
        {showLogIn &&(
            <LogginDialog setShowLogIn={setShowLogIn}/>
        )}
         
    </>
   
    
  )
}

export default SongCard