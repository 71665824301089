import {useState, useEffect, useContext} from 'react'
import Axios from 'axios';
import {useNavigate} from 'react-router-dom';
import { UserStoreContext } from '../Contexts/UserStoreContext';
const useFetch = (request) => {
    const {set_is_logged_in, set_user_store} = useContext(UserStoreContext)
    const navigate = useNavigate()
    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(false);
    const[error, setError] = useState(null);

    useEffect(()=>{ 
       
        if(request){
            setIsLoading(true)
          
            //const url = 'https://api.geniux.co.za/geniuxmusic/api/login.php';
            const url = 'https://api.geniux.co.za/geniuxmusic/api/reset_password.php';
            let cancel
            Axios({
                method: 'post',
                url: url, 
                data: {
                    username: request.username,
                    v_pin: request.v_pin,
                    newpassword: request.newpassword,
                },
                cancelToken: new Axios.CancelToken(c => cancel = c),

            })
            
            .then(response=>{
                setIsLoading(false)
         
                if(response.data[0] && response.data[0].response==="success"){
                    setData(response.data)
                    setError(null)

                    // localStorage.setItem('user_store', JSON.stringify(...response.data))
            
                    set_is_logged_in(true)
                    set_user_store(...response.data)
                    
                    navigate('/')

                }else{
                    if(response.data.response && response.data.response==="user_id_empty"){
                        setData(null)
                        setError('user_id_empty');  
                    }else if(response.data.response && response.data.response==="verification_pin_empty"){
                        setData(null)
                        setError('verification_pin_empty');  
                    }else if(response.data.response && response.data.response==="new_password_empty"){
                        setData(null)
                        setError('new_password_empty');  
                    }else if(response.data.response && response.data.response==="password_is_short"){
                        setData(null)
                        setError('password_is_short');  
                    }else if(response.data.response && response.data.response==="user_not_found"){
                        setData(null)
                        setError('user_not_found');  
                    }else if(response.data.response && response.data.response==="verification_failed"){
                        setData(null)
                        setError('verification_failed');  
                    }else{
                        setData(null)
                        setError('unknown_err');  
                    }
                }
           
               
            })
            .catch(err=>{
                if(!Axios.isCancel(err)){
                    setData(null)
                    setError('network_err');
                    setIsLoading(false)
                }
            })
            return () => cancel()
        }
     
        
    },[request])

    return {data, isLoading, error}
}

export default useFetch
