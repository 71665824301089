import React, {Component} from "react";
import {useState, useEffect} from 'react'
import RssFeedIcon from '@mui/icons-material/RssFeed';
import CommentIcon from '@mui/icons-material/Comment';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Button from '@mui/material/Button';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import GetAppIcon from '@mui/icons-material/GetApp';
import {Link, useNavigate  }  from'react-router-dom';

import TimeAgo from 'timeago-react'; 
import ViewNotification from '../Dialogs/ViewNotification.js'
const NotificationCard = (props) => {


    const navigate = useNavigate();
    const [data,setData] = useState(null);


    useEffect(() => {
        if(props.data){
            setData(props.data)
        }
    }, [props.data])

   

    const goto = () =>{
        if(data){
            if(data.type==="followed"){
                navigate('/profile/'+data.from_user_id);
            }else if(data.type==="commentedsong"){
                navigate('/playing/'+data.song_id);
            }else if(data.type==="songliked"){
                 navigate('/playing/'+data.song_id);
            }else if(data.type==="songdisliked"){
                 navigate('/playing/'+data.song_id);
            }else if(data.type==="commentliked"){
                 navigate('/playing/'+data.song_id+'?comment_id='+data.comment_id);
            }else if(data.type==="commentdisliked"){
                 navigate('/playing/'+data.song_id+'?comment_id='+data.comment_id);
            }else if(data.type==="likedcommentreply"){
                 navigate('/playing/'+data.song_id+'?comment_id='+data.commented_id);
            }else if(data.type==="dislikedcommentreply"){
                 navigate('/playing/'+data.song_id+'?comment_id='+data.commented_id);
            }else if(data.type==="commentreplied"){
                 navigate('/playing/'+data.song_id+'?comment_id='+data.commented_id);
            }else if(data.type==="songdownloaded"){
                 navigate('/playing/'+data.song_id);
            }else if(data.type==="albumdownloaded"){
                 navigate('/playing/'+data.song_id);
            }
        }
    }


    const[isMenuOpened,setisMenuOpened] = useState(false)



    return (
            <>
            {data &&(
            <>
                <Button className={data.status==="seen"?"mynot fadefx":"mynot fadefx active"} onClick={()=>setisMenuOpened(true)} color="secondary">
                    <div className="lft">
                        {data.user_avatar.length>2?
                            <img className="userpic" alt="User" src={"https://api.geniux.co.za/geniuxmusic/api/media/avatars/smaller/?file_name="+data.user_avatar}/>
                        :
                            <div className="userpic">
                                <p>{data.artist_name.substring(0, 1)}</p>
                            </div>
                        }
                    

                    
                        <div className="details">
                        
                            <p>{data.artist_name}</p>
                            <p>
                            {
                                data.type==="followed"?
                                    <RssFeedIcon/>
                                : data.type==="commentedsong"?
                                    <CommentIcon/>
                                :data.type==="songliked"?
                                    <FavoriteIcon/>
                                :data.type==="songdisliked"?
                                    <ThumbDownAltIcon/>
                                :data.type==="commentliked"?
                                    <ThumbUpIcon/>
                                : data.type==="commentdisliked"?
                                    <ThumbDownAltIcon/>
                                : data.type==="likedcommentreply"?
                                    <ThumbUpIcon/>
                                : data.type==="dislikedcommentreply"?
                                    <ThumbDownAltIcon/>
                                :data.type==="commentreplied"?
                                    <ModeCommentIcon/>
                                :data.type==="songreleased"?
                                    <MusicNoteIcon/>
                                :data.type==="songdownloaded"?
                                    <GetAppIcon/>
                                :data.type==="albumdownloaded"?
                                    <GetAppIcon/>
                                :""
                            }
                            {data.message}
                            </p>
                        
                        </div>
                    </div>
                    <div className="rgt">
                    <TimeAgo datetime={data.date_added} />
                    </div>
                </Button>
                {isMenuOpened &&(
                    <ViewNotification data={data} setisMenuOpened={setisMenuOpened}/>
                )}
            </>
            )}
               
                       
                </>
     
    )
}

export default NotificationCard
