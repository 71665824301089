import {useState, useEffect, useContext} from 'react'
import { UserStoreContext } from '../Contexts/UserStoreContext';
import Axios from 'axios';
const useFetch = (request) => {
    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(true);
    const[error, setError] = useState(null);
    
    
    const {is_logged_in, set_is_logged_in,set_user_store, user_store} = useContext(UserStoreContext)
    

    useEffect(()=>{ 

        setIsLoading(true)
        if(request){
            const url = 'https://api.geniux.co.za/geniuxmusic/api/send_comment.php';
            let cancel
            Axios({
                method: 'post',
                url: url,
                data: {
                    songId: request.song_id,
                    message: request.message,
                    myuserid: is_logged_in?user_store.user_id:"",
                    mytoken: is_logged_in?user_store.user_token:"",
                },
                cancelToken: new Axios.CancelToken(c => cancel = c),

            })
            
            .then(response=>{
                if(response.data && response.data.length>0){
                    setData(response.data)
                    setIsLoading(false)
                    setError(null);  
                }else{
                    if(response.data.response){
                        if(response.data.response==="user_not_found" || response.data.response==="credentials_not_provided"){
                            set_is_logged_in(false)
                            set_user_store(null)
                        }
                    }
                    setData([])
                    setIsLoading(false)
                    setError(null);  
                }
           
               
            })
            .catch(err=>{
                if(!Axios.isCancel(err)){
                    setError('network_err');
                    setIsLoading(false)
                }  
            })
            return () => cancel()
        }else{
            setIsLoading(false)
        }
    
    
    },[request])
 
    return {data, isLoading, error}
}

export default useFetch
