import {useState, useEffect, useContext} from 'react'
import { UserStoreContext } from '../Contexts/UserStoreContext';
import Axios from 'axios';
const useFetch = (request) => {
    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(true);
    const[error, setError] = useState(null); 
    

    const {is_logged_in, user_store} = useContext(UserStoreContext)
    




    useEffect(()=>{ 

        setIsLoading(true)
        if(request){
    
            const url = '/geniuxmusic/api/update_notification_status.php';
            //const url = 'api/fetch_songs.php';
            let cancel
            Axios({
                method: 'post',
                url: url,
                data: {
                    notificationId: request.notification_id,
                    status: 'seen',
                    myuserid: is_logged_in?user_store.user_id:"",
                    mytoken: is_logged_in?user_store.user_token:"",
                },
                cancelToken: new Axios.CancelToken(c => cancel = c),

            })
            
            .then(response=>{
                if(response.data.length>0){
                    setData(response.data)
                    setIsLoading(false)
                    setError(null);  
                    
                }else{
                    setData([])
                    setIsLoading(false)
                }
           
               
            }) 
            .catch(err=>{
                if(!Axios.isCancel(err)){
                    setError('network_err');
                    setIsLoading(false)
                }
            })
            return () => cancel()
        }else{
            setIsLoading(false)
        }
    
    
    },[request])
 
    return {data, isLoading, error}
}

export default useFetch
