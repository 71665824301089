import React, {Component} from "react";
import {useState,useEffect,useContext} from 'react'
import {PlayContext} from '../Contexts/PlayContext.js'
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {Link, useNavigate} from 'react-router-dom';
import TimeAgo from 'timeago-react'; 
import Tooltip from '@mui/material/Tooltip';
const AlbumCard = (props) => {
    const navigate = useNavigate();
    const[data,setData] = useState(null)

    useEffect(()=>{
        if(props.data){
            setData(props.data)
        }
    },[props.data])


    const {PlayData,PlayStatus} = useContext(PlayContext)
   

    return (
        <>
            {data && (
                <div className={PlayData && PlayData.data.song.song_id===data.songs[0].song_id?"albumcard active": "albumcard"} >
                    <div className="contents">
                        <div className="songcover" onClick={()=>navigate("/playing/album/"+data.album.album_id)}>
                            <img className="artcover" aria-label="Art Cover" src={data.album.artcover.length>1?"https://api.geniux.co.za/geniuxmusic/api/media/arts/medium/?file_name="+data.album.artcover:"/assets/images/defaultsongart.jpg"}/>
                            {PlayData && PlayData.data.song.album_id===data.album.album_id &&(
                                <>
                                    {PlayStatus==="playing"?<PauseIcon className="active"/>:<PlayArrowIcon/>}
                                </>
                            )
                            }

                        </div>
                        <div className="details">
                            <Tooltip title={data.album.title} placement="top">
                                <p><Link to={"/playing/"+data.songs[0].song.song_id}>{data.album.title}</Link></p>
                            </Tooltip>
                            <Tooltip title={"Published by "+data.user.artist_name +". Released date: "+data.album.date_added.substr(0,11)} placement="bottom">
                                <p><Link to={"/profile/"+data.user.user_id}>{data.user.artist_name}</Link><TimeAgo datetime={data.album.date_added} /></p>
                            </Tooltip>
                        </div>
                    </div>
                </div>     
            )}
        </>
         
    )
}

export default AlbumCard
