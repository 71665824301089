import React, {Component} from "react";
import {useState, useEffect} from 'react'
import useFetch from '../APIs/FacebookGetPosts.js';
import Linkify from 'linkify-react';
import TimeAgo from 'timeago-react'; 
import {Link} from 'react-router-dom';
import LoadingSkeleton from "../LoadingSkeletons/Banner01.js";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import { prominent } from 'color.js';
// import required modules
import {Autoplay } from "swiper/modules";

import FacebookData from './OfflineUpdates/Staytuned.json'
const RecommendedBanners = (props) => {

    const [request,setRequest] = useState(true);
    const [data, setData] = useState(null);
    const {data:FetchedData,isLoading, error} = useFetch(request)

    useEffect(()=>{
        setData(FacebookData)
    },[])

    return (
        <>
                <div className="htitle">
                    <div className="lft"><p>Stay tuned</p></div>
                    <div className="rgt"><p><a href="https://web.facebook.com/music.geniux.co.za" target="_blank">See more</a></p></div>
                </div>
                {data && (
                <div className="banner01">
                    <Swiper
                        loop={true}
                        autoplay={{
                            delay: 15000,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay]}
                        className="mySwiper"
                    >
                        {data.slice(0,10).map((r,index) =>(
                            <SwiperSlide key={index}>
                                <div className="card" >
                                        <div className="background"></div>
                                        <div className="lft">
                                            <p>
                                                {r.message.length>150?r.message.substring(0, 150) + "...": r.message} <TimeAgo datetime={r.updated_time} />
                                            </p>
                                            <p>
                                                {r.link.isExternalLink &&(
                                                    <a href={r.link.url + (props.is_logged_in && r.link.isMailto ? 'UserId: '+ props.user_store.user_id + '&body=Hi there, %0D%0AI have some questions I’d like to ask. %0D%0A %0D%0A' + props.user_store.artist_name + '%0D%0ABest regards': 'Geniux Music&body=Hi there, my name is __________%0D%0AI have some questions I’d like to ask.%0D%0A %0D%0AAnonymous user%0D%0ABest regards')} target="_blank">{r.link.label}</a>
                                                )}
                                                {!r.link.isExternalLink &&(
                                                    <Link to={r.link.url}>{r.link.label}</Link>
                                                )}
                                            </p>
                                        </div>
                                        <div className="artwork">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="35.196" height="35.21" viewBox="0 0 35.196 35.21">
                                                <g id="Group_2" data-name="Group 2" transform="translate(-14.527 -14.52)">
                                                    <g id="Outline">
                                                    <path id="Path_3" data-name="Path 3" d="M26.806,39.133A12.283,12.283,0,0,1,14.527,26.826,12.32,12.32,0,0,1,26.833,14.52a12.284,12.284,0,0,1,12.28,12.306A12.321,12.321,0,0,1,26.806,39.133Zm.027-23.018A10.712,10.712,0,1,0,37.518,26.826,10.723,10.723,0,0,0,26.833,16.115Z" fill="#fff"/>
                                                    </g>
                                                    <g id="Outline-2" data-name="Outline">
                                                    <path id="Path_4" data-name="Path 4" d="M43.464,49.48a6.01,6.01,0,1,1,6.009-6.01A6.009,6.009,0,0,1,43.464,49.48Zm.013-11.509a5.5,5.5,0,1,0,5.486,5.5A5.505,5.505,0,0,0,43.477,37.971Z" fill="#fff" stroke="#fff" strokeWidth="0.5"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        
                                </div>
                            </SwiperSlide>
                        ))}
                    
                    
                    </Swiper>
                </div>
                )}
                {((isLoading || error) && (!data)) &&(
                    <LoadingSkeleton/>
                )}
        
        </>

    )
}
export default RecommendedBanners