import React, {Component} from "react";
import {useState, useEffect, useContext} from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from '@mui/material/TextField';
import { UserStoreContext } from '../../../Components/Contexts/UserStoreContext';
import Button from '@mui/material/Button';
import {Link, useNavigate, useParams}  from'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import useFetch from '../../../Components/APIs/FetchSongs.js';
import useUpdateSong from '../../../Components/APIs/UpdateSong.js';
import SongUpdated from '../../../Components/Dialogs/SongUpdated.js';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import ImageCrop from '../../../Components/ManageSong/ImageCrop';
import AddLyrics from '../../../Components/Components/AddLyrics';
import DeleteSong from '../../../Components/Dialogs/DeleteSong.js';
import IsLoading from '../../../Components/isLoading.js';
import DeleteIcon from '@mui/icons-material/Delete';
export default function ManageSong(props) {
    
    const navigate = useNavigate()
    const {is_logged_in, user_store} = useContext(UserStoreContext)
    
    const[innerWidth,setinnerWidth] = useState(window.innerWidth);
    const [pagescroll, setPageScroll] = useState(0)
    useEffect(()=>{
        window.scrollTo(0, 0)
        window.onscroll = () => {
            setPageScroll(window.pageYOffset)
        }
        const updateInnerWidth = () => {
            setinnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateInnerWidth);
        return () => window.removeEventListener("resize", updateInnerWidth)
    },[])

    useEffect(()=>{
        if(is_logged_in===true || is_logged_in===false){
            if(is_logged_in===false){
                navigate('/login')
            }
        }
    },[is_logged_in])

    

    const {song_id} = useParams();
    const [FetchGenres] = useState([{
        "id": "16",
        "orderId": "1",
        "genre": "Hiphop"
    },
    {
        "id": "17",
        "orderId": "2",
        "genre": "House"
    },
    {
        "id": "24",
        "orderId": "3",
        "genre": "RNB"
    },
    {
        "id": "3",
        "orderId": "4",
        "genre": "Classical"
    },
    {
        "id": "39",
        "orderId": "5",
        "genre": "Instrumentals"
    },
    {
        "id": "38",
        "orderId": "6",
        "genre": "Others"
    }])

    
    const [isDeletingSong, setIsDeletingSong] = useState(false);
    //Song Fields
    const [isAddingLyrics, setIsAddingLyrics] = useState(false);
    const [isUploadingArt, setIsUploadingArt] = useState(false);

    const [lyrics, setLyrics] = useState('');
    const [song_title, setTitle] = useState('');
    const [genre, setGenre] = useState('');
    const [description, setDescription] = useState('');
    const [artcover, setArtCover] = useState(null);


    const [album_title, setAlbumTitle] = useState('');


    
   


    //Fetch Song Data
    const [request, setRequest] = useState(null)

    const [FetchedSong, setFetchedSong] = useState(null)
    const {data:fetch_song,isLoading:fetch_loading, error:fetch_error} = useFetch(request)
 

    const fetch_request = ()=>{
        setFetchedSong(null)
        setRequest({
            "song_id": song_id,
            "user_id":"",
            "search": "",
            "genre":"",
            "sort": "",
            "page": 1,
        })
    }
    
    useEffect(()=>{
        if(props.data && is_logged_in){
          
            if(props.data.total_results>0){
            
                if(props.data.results[0].user.user_id === user_store.user_id){
             
                    setFetchedSong(props.data)
                }else{
                    fetch_request()
                }
        
            }else{
                fetch_request()
            }
          }else{
            fetch_request()
          }
    },[song_id])
    useEffect(()=>{
        if(fetch_song){
            setFetchedSong(fetch_song)
            if(fetch_song.total_results>0){
                setTitle(fetch_song.results[0].song.title)
                setDescription(fetch_song.results[0].song.description)
                setGenre(fetch_song.results[0].song.genre)
                setLyrics(fetch_song.results[0].song.lyrics)
                if(fetch_song.results[0].song.type==="album"){
                    setAlbumTitle(fetch_song.results[0].song.album_title)
                }
                // Scroll to current selected interest 
                setTimeout(()=>{
                    if(document.querySelector(".selectoptions p.active")!== null){
                        document.querySelector(".selectoptions p.active").scrollIntoView({
                            block: 'center',
                            inline: 'center',
                        });
                        window.scrollTo(0, 0);
                    }
                },[1000])
            }

        }
       
    },[fetch_song])

  


    //Handle Error Onclick



    ///



    //Update Song
    const [data, setData] = useState(null);
    const [post_request,setPostRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useUpdateSong(post_request)
  
    

    const[isDialogOpened, setisDialogOpened] = useState(false)
    useEffect(()=>{
        if(FetchedData){
            setisDialogOpened(true)
            setData(...FetchedData)
        }
    },[FetchedData])

    function handleSongTile(e){
        if(e.target.value.length<=90){
            setTitle(e.target.value)
        }
    }

    function handleAlbumTitle(e){
        if(e.target.value.length<=90){
            setAlbumTitle(e.target.value)
        }
    }
    function handleSongDescription(e){
        if(e.target.value.length<=500){
            setDescription(e.target.value)
        }
    }


    const send_post_request = () =>{
        setData(null)
        setPostRequest({
            'song_id':song_id,
          'song_title':song_title,
          'album_title':album_title,
          'genre':genre,
          'description':description,
          'lyrics': lyrics,
          'artcover':artcover,
        })
      }
      const onhandle_submit= (e) =>{
        e.preventDefault()
        if(song_title===""){
          document.getElementById('song_title').focus()
        }else{
            send_post_request()
        }
        
    
      }


    const saveLyrics=()=>{
        send_post_request()
    }

  return (
    <>
   
        {innerWidth<992 &&(
            <div className="navbar">
                <div className="wrapper">
                    <div className="lft">
                        <IconButton className="icon" onClick={()=>navigate(-1)}>
                            <ArrowBackIcon />
                        </IconButton> 
                        <div className="titles">
                            <p>UPDATE SONG</p>
                            {pagescroll> 80  &&(<p>{(FetchedSong && FetchedSong.total_results>0) &&(FetchedSong.results[0].song.title)}</p>)}
                        </div>
                    
                    </div>
                    <div className="rgt">
                        {(FetchedSong && FetchedSong.total_results>0) &&( 
                            <IconButton className="icon" onClick={()=>setIsDeletingSong(true)}>
                                <DeleteIcon />
                            </IconButton>  
                        )}
                    </div>
                </div>
            </div>
        )}
        <div className="mycontainer">

            {/* My  path */}
            {innerWidth>992 &&(
                <div className="mypath">
                    <div className="wrapper">
                        <div className="lft">
                            {window.history.length>1?
                            <Button onClick={()=>navigate(-1)}>
                                <KeyboardBackspaceIcon/>
                            </Button>  
                            :
                            <Button onClick={()=>navigate(-1)} disabled="true">
                                <KeyboardBackspaceIcon/>
                            </Button>  
                            }
                        </div>
                        <div className="rgt">
                            <p>
                                <Link to={"/uploads/update/"+song_id} className="active">UPDATE SONG</Link>
                                <Link to={"/uploads/update/"+song_id} className="">{(FetchedSong && FetchedSong.total_results>0) &&(FetchedSong.results[0].song.title)}</Link>
                            </p>
                            {(FetchedSong && FetchedSong.total_results>0) &&(
                                <IconButton className="icon" onClick={()=>setIsDeletingSong(true)}>
                                    <DeleteIcon />
                                </IconButton>  
                                )
                            }
                            
                        </div>
                    </div>
                </div>
            )}
            <div className="wrapper">
                <div className="uploadmusic" >
                    {fetch_loading && (<IsLoading/>)}
                    {(FetchedSong && FetchedSong.total_results>0) && (
                    <form className="myform" onSubmit={onhandle_submit} >
                        <div className="row">
                            <div className="col-12 col-lg-7">
                                <div className="uploadcover">
                                    <div className="imgcrop">
                                        {!isUploadingArt &&(
                                            FetchedSong.results[0].song.artcover.length>1?
                                                <img className="songcover" onClick={()=>setIsUploadingArt(true)} aria-label="Art Cover" src={"/geniuxmusic/data/arts/"+FetchedSong.results[0].song.artcover}/>
                                                :
                                                <img className="songcover" onClick={()=>setIsUploadingArt(true)} aria-label="Art Cover" src="/assets/images/defaultsongart.jpg"/>
                                            
                                        )}
                                        
                                        {isUploadingArt &&(<ImageCrop setArtCover={setArtCover}/>)}
                                
                                        
                                    </div>
                                    {FetchedSong.results[0].song.type==="album"?
                                        <div className="titles" onClick={()=>setIsUploadingArt(true)}>
                                            <p>{isUploadingArt? "Choose":"Click to Update"}</p>
                                            <p>Insert/update image of your album art cover</p>
                                        </div>
                                        :
                                        <div className="titles" onClick={()=>setIsUploadingArt(true)}>
                                            <p>{isUploadingArt? "Choose":"Click to Update"}</p>
                                            <p>Insert/update image of your song art cover</p>
                                        </div>
                                    }
                                    
                                </div>  
                                {FetchedSong.results[0].song.type==="album"?
                                <>
                                <div className="albumtitle">
                                    <p>Album Title</p>
                                    <input type="text" placeholder={album_title}  onChange={handleAlbumTitle} />
                                    {/* <span>{FetchedSong.results[0].album_title}</span> */}
                                </div> 
                                <hr/>
                                </>
                                
                                :""
                                }
                                
                                <TextField variant="filled" onChange={handleSongTile} id="song_title" label="Song Title"  value={song_title}/>
                                <p className="mylabel">Select Genre</p>
                                <div className="selectoptions">
                                    {FetchGenres.map((data,index)=>(
                                        <p key={index} onClick={()=>setGenre(data.genre)} className={genre===data.genre?"active":""}>{data.genre}</p>
                                    ))}
                                </div>
                                <TextField variant="filled" label="Description (Optional)" value={description} multiline rows={4} onChange={handleSongDescription}/>
                                <div className="addlyrics">
                                    <div className="lft">
                                        <p className="">Lyrics(Optional)</p>
                                        <p className="">
                                            {lyrics!=="" &&(<>Added</>)}
                                        </p>
                                        
                                    </div>

                                    
                                    
                                    <div  className="rgt">
                                        <Button onClick={()=>setIsAddingLyrics(true)} >
                                        { lyrics!==""?
                                        <>Modify Lyrics</>
                                        :
                                        <>Add Lyrics</>
                                        }
                                        </Button>
                                    </div>
                                </div>
                                <div className="myerr">  
                                    {error==="song_not_found"?<p>Song was not found or has been removed <ErrorIcon/></p>:""}
                                    {error==="song_title_empty"?<p>Song title is required<ErrorIcon/></p>:""}
                                
                                    {error==="failed_to_move_art_file"?<p>There was an error, try again to update without art cover <ErrorIcon/></p>:""}
                                    {error==="art_file_is_huge"?<p>Art cover size is limited to 10MB <ErrorIcon/></p>:""}
                                    {error==="art_file_error_occured"?<p>We could not update, try to uplaod different art cover <ErrorIcon/></p>:""}
                                    {error==="art_file_format_error"?<p>Art cover is not allowed, Only JPG/PNG allowed <ErrorIcon/></p>:""}

                                    {error==="unknown_err"?<p>There was an error, contact developers <ErrorIcon/></p>:""}
                                    {error==="network_err"?<p>Check your internet connectivity <ErrorIcon/></p>:""}

                                    {error==="not_logged_in"?<p>Try log out and log in again <ErrorIcon/></p>:""}
                                </div>
                                <div className="btns">
                                    <Button type="submit" className="defaultBTN" >Update Song {(isLoading) && <CircularProgress/>} </Button>
                                </div> 
                            </div>
                            <div className="col-12 col-lg-5">
                                <div className="rightmenu">
                                    <div className="content-container">
                                        <div className="htitle">
                                            <div className="lft"><p>YOUR MUSIC, MATERIALS AND INFORMATION</p></div>
                                        </div>
                                        <p>
                                        When you upload your Recordings through our Services, you are asked to submit Metadata as well as cover artwork for use on the Stores. You submit the Recordings, cover artwork and any other information and material (jointly “Material”) at your own expense and in formats required for use on the Stores.
                                        <br/><br/>
                                        You are fully responsible for everything you submit to us. If we find it unsuitable, we reserve the right to, in our sole discretion, remove the information and/or prevent you from using our Services and/or any or all Stores.
                                        <br/><br/>
                                        You submit the Recordings to us in a pre-agreed format and for a pre-agreed release date. The release date will be locked and you may not change it.
                                        <br/><br/>
                                        </p>
                                        <div className="htitle">
                                            <div className="lft"><p>Grands of rights</p></div>
                                        </div>
                                        <p>
                                        <br/>
                                        <br/>
                                        <b>By using our Services you grant us, during the Term and throughout the Territory,</b><br/><br/>
                                            The non-exclusive right to use your className, photographs, likenesses, cover artwork, biographical and other information attributable to you, which you have submitted to Geniux Music.<br/><br/>
                                            The right for Geniux Music to sublicense or otherwise transfer the above rights to any and all Stores.<br/><br/>
                                            The right for Geniux Music to perform Metadata corrections (where necessary).<br/><br/>
                                            The right for Geniux Music to synchronize and authorize others to synchronize your Recordings with visual images, to combine portions of your Recordings with still or moving images or as a live stream.<br/>
                                            <br/><br/>
                                        </p>
                                        <div className="htitle">
                                            <div className="lft"><p>DELETE SONG</p></div>
                                        </div>
                                        <p>
                                            We will not be responsible for your loss, and there's no turning back.
                                        </p>
                                        <div className="delete-account">
                                            <Button variant="outlined" onClick={()=>setIsDeletingSong(true)}>Delete Song</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  


                    </form>
                    )}
                </div>
                {FetchedSong && FetchedSong.total_results<1 &&(
        
                    <div className="no_data_msg">
                        <div className="container">
                            <div className="icon">
                                <img src="/assets/images/no_music_found.svg" />
                            </div>
                            
                            <p>No results found, file might be deleted or link is broken.</p>
                            <Button onClick={()=>fetch_request()}>
                                Retry
                            </Button>
                        </div>
                    </div>
                )}
                {fetch_error==="network_err"&&(
        
                    <div className="no_data_msg">
                        <div className="container">
                            <div className="icon">
                                <img src="/assets/images/no_music_found.svg" />
                            </div>
                            
                            <p>Check your internet connectivity</p>
                            <Button onClick={()=>fetch_request()}>
                                Retry
                            </Button>
                        </div>
                    </div>
                )}
            </div>
       
        </div>
   
    {(isAddingLyrics && FetchedSong && is_logged_in) &&(
        <AddLyrics lyrics={lyrics} setLyrics={setLyrics} song_title={song_title}setIsAddingLyrics={setIsAddingLyrics} saveLyrics={()=>saveLyrics()} innerWidth={innerWidth}/>
      )}

    {(isDeletingSong && FetchedSong && is_logged_in) &&(
        <DeleteSong song_title={song_title} song_id={song_id} myuserid={user_store.user_id} setIsDeletingSong={setIsDeletingSong}/>
    )}
    
    {(data && isDialogOpened && FetchedSong && is_logged_in) &&(
        <SongUpdated song_title={song_title} song_id={song_id} setisDialogOpened={setisDialogOpened} setIsDeletingSong={setIsDeletingSong}/>

    )}
    
   
      


 
    </>
  );
}

