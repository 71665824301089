import {useState, useEffect, useContext, useRef, useCallback} from 'react';
import {Helmet} from "react-helmet";
import React, {Component} from "react";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigationMenuToggle from '../Navigation/NavigationMenuToggle';
import {Link, useNavigate, useSearchParams, useParams} from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import ValidateUser from '../UserCredentials/ValidateUser';

import { UserStoreContext } from '../Contexts/UserStoreContext';

import Card from '../Cards/UserCard.js';
import LoadingSkeleton from '../LoadingSkeletons/UserCard03.js';

import useFetch from '../APIs/FetchFollowers.js';

import FilerDialog from './FilterDialog.js';

import NoInternet from '../Dialogs/NoInternet.js';

export default function Followers (props) {
    const {is_logged_in, user_store} = useContext(UserStoreContext);
    const navigate = useNavigate();
    const[innerWidth,setinnerWidth] = useState(window.innerWidth)
    useEffect(()=>{
        window.scrollTo(0, 0)
        const updateInnerWidth = () => {
            setinnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateInnerWidth);
        return () => window.removeEventListener("resize", updateInnerWidth)
    },[])

    const [searchParams, setSearchParams] = useSearchParams();
    const sort_param = searchParams.get("sort")
    const {user_id} = useParams();

  //userId,songId,search_string,genre,sort,pageNumber,
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState(null);
  const [request, setRequest] = useState(null);
  const {data:FetchedData,isLoading, error} = useFetch(request)

  useEffect(()=>{
      if(FetchedData){
        if(FetchedData.total_results>0){
          if(data && data.length>0){
            setData([...data, ...FetchedData.results])
          }else{
              setData(FetchedData.results)
          }
        }
      }
  },[FetchedData])


  const send_request =()=>{
    if(is_logged_in){
        setRequest({
            "sort":sort_param?sort_param:"newest",
            "user_id": user_id,
            "type":props.group,
            "page":pageNumber,
        })
    }
  }

  useEffect(()=>{
      send_request()
  },[pageNumber, is_logged_in])


  const LoadMore = ()=>{
      const currpage = pageNumber+1;
      setPageNumber(currpage)
  }

  const observer = useRef()
  const lastBookElementRef = useCallback(node=>{

      if(observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries=>{
          if(entries[0].isIntersecting){

            if(data && FetchedData && FetchedData.results.length>0){
              LoadMore()
            }
              
          }
      })
      if(node) observer.current.observe(node)
     
  },[FetchedData])

  useEffect(()=>{
    setData(null)
    setPageNumber(1)
    send_request()
},[sort_param])

  //Handle Error Onclick
  const handleError = ()=>{
      send_request()
  }
  const[showNoInternet,setShowInternet] = useState(false)
  useEffect(()=>{
      if(error){
          setShowInternet(true)
      }
  },[error])

  const closeModal = ()=>{
      setShowInternet(false);
  }

    
    //
    const [isFilterOpened, setFilterOpened] = useState(false)


    return (
        <>
                
                <div className="myfilter">
                    <div className="lft">
                        <p>Total {props.group === 'followers' ? "followers" : "followings"} {FetchedData && ("("+FetchedData.total_results+")")} </p>
                        <p className='subtitle'>Filtered by: {sort_param==="oldest"?"Oldest":"Newest"}</p>
                    </div>
                    <div className="rgt">
                        <Button className="loadmorebtn" color="primary" onClick={()=>setFilterOpened(true)}>Filter</Button>
                    </div>
                </div>
                <div className="row">
                    {data && (
                        data.map((song,index)=>{
                            return <div className="col-6 col-md-4 col-lg-3 col-xxl-2" key={index}><Card key={index} data = {song} Data = {data} index={index}/></div>
                        }
                    ))}
                    {(isLoading) &&(
                        <>
                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                        </>
                    )}
                </div>
                <div ref={lastBookElementRef}></div>
                    
            
                {/* Feedbacks */}
                {(!data && error=="network_err") &&(
                    <div className='wrapper'>
                        <div className="no_data_msg">
                            <div className="container">
                                <div className="icon">
                                    <img src="/assets/images/no_user_found.svg" />
                                </div>
                                <p>Check your internet connectivity</p> 
                                <Button onClick={()=>send_request()}>
                                    Retry
                                </Button>
                            </div>

                        </div>
                    </div> 
                )}  

                {(!isLoading && FetchedData && FetchedData.total_results<1) &&(
                    <div className='wrapper'>
                        <div className="no_data_msg">
                            <div className="container">
                                <div className="icon">
                                    <img src="/assets/images/no_user_found.svg" />
                                </div>
                                <p>You don't have liked music</p> 
                                <Button onClick={()=>navigate(-1)}>
                                    Go Back
                                </Button>
                            </div>

                        </div>
                    </div> 
                )}   
                {(!data && error=="network_err")&&(
                    <NoInternet closeModal={closeModal} handleError={handleError}/>
                )}  
                {isFilterOpened &&(
                    <FilerDialog setFilterOpened={setFilterOpened} sort={sort_param} group={props.group} user_id={user_id}/>
                )}
        
        </>
    )
}



