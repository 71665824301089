import {useState, useEffect, useContext} from 'react'
import { UserStoreContext } from '../Contexts/UserStoreContext';
import Axios from 'axios';
import {useNavigate} from 'react-router-dom'; 
const useFetch = (request) => {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(true);
    const[error, setError] = useState(null); 
    

    const[UploadPercentage, setUploadPercentage] = useState(null); 

    const {is_logged_in, set_is_logged_in,set_user_store, user_store} = useContext(UserStoreContext)
    




    useEffect(()=>{ 
        setData(null)
        setIsLoading(true)
        setError(null)
        if(is_logged_in && request){

            const url = 'https://api.geniux.co.za/geniuxmusic/api/upload_album.php';

            var formData = new FormData()
           
            if(request.songs){
                var songs = request.songs;
                for(var i = 0; i < songs.length; i++) {
                    formData.append('songs[]', songs[i]);
                }

            }
             
            if(request.artcover){
                formData.append("artcover", request.artcover.originFileObj)
            }
   
            formData.append('album_title', request.album_title)
            formData.append('genre', request.genre)
            formData.append('description', request.description)
            formData.append('myuserid', user_store.user_id)
            formData.append('mytoken', user_store.user_token)
            let cancel
            Axios({
                method: 'post',
                url: url,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: ProgressEvent =>{
                    setUploadPercentage(
                        parseInt(
                            Math.round((ProgressEvent.loaded * 100)/ProgressEvent.total)
                            )
                    )
                    // 
                    //     setUploadPercentage(null)
                    // },[10000])
                },
                
                cancelToken: new Axios.CancelToken(c => cancel = c),

            })
            
            .then(response=>{
                if(response.data && response.data.response==="success"){
                     navigate('/uploads/upload/album/complete/'+response.data[0].album_id)
                }else{
                    if(response.data && response.data.response==="album_title_is_required"){
                        setError('album_title_is_required'); 
                    }else if(response.data && response.data.response==="user_not_found"){
                        setError('user_not_found'); 
                        set_is_logged_in(false)
                        set_user_store(null)
                    }else if(response.data && response.data.response==="songs_not_attached"){
                        setError('songs_not_attached'); 
                    }else if(response.data && response.data.response==="credentials_not_provided"){
                        setError('credentials_not_provided'); 
                        set_is_logged_in(false)
                        set_user_store(null)
                    }else if(response.data && response.data.response==="files_contains_error"){
                        setError('files_contains_error'); 
                    }else if(response.data && response.data.response==="failed_to_query"){
                        setError('failed_to_query'); 
                    }else{
                        setError('unknown_error'); 
                    }
                    setUploadPercentage(null)
                    setIsLoading(false)
                }
           
               
            })
            .catch(err=>{
                if(!Axios.isCancel(err)){
                    setError('network_err');
                    setIsLoading(false)
                    setUploadPercentage(null)
                }
               
            })
            return () => cancel()
        }else{
            setIsLoading(false)
        }
    
    
    },[is_logged_in,request])
 
    return {data, isLoading, error, UploadPercentage}
}

export default useFetch
