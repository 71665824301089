import {useState, useEffect, useContext} from 'react'
import { UserStoreContext } from '../../Contexts/UserStoreContext';
import Axios from 'axios';

const useFetch = (request) => {
    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(true);
    const[error, setError] = useState(null);
    
    
    const {is_logged_in, user_store} = useContext(UserStoreContext)
    

    useEffect(()=>{ 
        setError(null)
        setIsLoading(true)
        if(request){

            const url = '/geniuxmusic/api/download_album.php';
            let cancel
            Axios({
                method: 'post',
                url: url,
                data: {
                    albumId: request.album_id,
                    myuserid: is_logged_in?user_store.user_id:"",
                    mytoken: is_logged_in?user_store.user_token:"",
                },
                cancelToken: new Axios.CancelToken(c => cancel = c),

            })
            
            .then(response=>{
                setIsLoading(false);

                const link = document.createElement('a');
                link.href = 'https://api.geniux.co.za/geniuxmusic/api/download.php?album='+request.album_id;
                link.setAttribute(
                'download',
                "Download File"
                );
            
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                    
                
                if(response.data && response.data.length>0){
                    setData(response.data)
                    setError(null);  
                    
                }else{
                    setData(null)
                    setError('unknown_err');  
                }
           
               
            })
            .catch(err=>{
                setIsLoading(false)
                setError('network_err');   
            })
            return () => cancel()
        }else{
            setIsLoading(false)
        }
    
    
    },[request])
 
    return {data, isLoading, error}
}

export default useFetch
