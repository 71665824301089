import {useState, useEffect, useContext} from 'react'
import { UserStoreContext } from '../Contexts/UserStoreContext.js'
import Axios from 'axios';
const useFetch = (fields) => {
    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(true);
    const[error, setError] = useState(null); 
    

    const[UploadPercentage, setUploadPercentage] = useState(null); 

    const {is_logged_in, set_is_logged_in,set_user_store, user_store} = useContext(UserStoreContext)
    




    useEffect(()=>{ 
        setError(null)
        setIsLoading(true)
        if(is_logged_in && fields.fullname!==""){

            const url = 'https://api.geniux.co.za/geniuxmusic/api/update_account.php';
            var formData = new FormData()
           
            if(fields.new_user_avatar){
                formData.append('user_avatar', fields.new_user_avatar.originFileObj)
            }

   
            formData.append('fullname', fields.fullname)
            formData.append('interest', fields.interest)
            formData.append('email', fields.email)
            formData.append('bio', fields.bio)
            formData.append('showemail', fields.showemail)
            formData.append('showphone', fields.showphone)
            formData.append('newpassword', fields.newpassword)
            formData.append('currentpassword', fields.currentpassword)

            formData.append('myuserid', user_store.user_id)
            formData.append('mytoken', user_store.user_token)
            let cancel
            Axios({
                method: 'post',
                url: url,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: ProgressEvent =>{
                    setUploadPercentage(
                        parseInt(
                            Math.round((ProgressEvent.loaded * 100)/ProgressEvent.total)
                            )
                    )
                },
                
                cancelToken: new Axios.CancelToken(c => cancel = c),

            })
            
            .then(response=>{
                if(response.data){
                    setIsLoading(false)
                    if(response.data.response==="incorrect_current_password"){
                        setData(null)
                        setError('incorrect_current_password');  
                    }else if(response.data.response==="email_empty"){
                        setData(null)
                        setError('email_empty');  
                    }else if(response.data.response==="email_already_registered"){
                        setData(null)
                        setError('email_already_registered');  
                    }else if(response.data.response==="newpassword_is_short"){
                        setData(null)
                        setError('newpassword_is_short');  
                    }else if(response.data[0].response==="success"){
                        setData(response.data)
                        setError(null);  
                        // localStorage.setItem('user_store', JSON.stringify(...response.data))
               
                        set_user_store(...response.data)
                    }else{
                        setData(null)
                        setIsLoading(false)
                        setError('unknow_error');  
                    }
                    
                

                    
                }else{
                    setData(null)
                    setIsLoading(false)
                    setError('unknow_error');  
                }
           
               
            })
            .catch(err=>{
                if(!Axios.isCancel(err)){
                    setError('network_err');
                    setIsLoading(false)
                }
            })
            return () => cancel()
        }else{
            setIsLoading(false)
        }
    
    
    },[is_logged_in,fields])
 
    return {data, isLoading, error, UploadPercentage}
}

export default useFetch
