import {useState, useEffect} from 'react'
import Axios from 'axios';
const useFetch = () => {
    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(true);
    const[error, setError] = useState(null);


    useEffect(()=>{ 

        setIsLoading(true)

            const url = '/geniuxmusic/api/fetch_genre_stats.php';
            let cancel
            Axios({
                method: 'post',
                url: url,
                data: {
                    filter: "random",
                },
                cancelToken: new Axios.CancelToken(c => cancel = c),

            })
            
            .then(response=>{
                if(response.data[0] && response.data.length>0){

                    setData(response.data)
                    setIsLoading(false)
                    setError(null);  

                }else{
                    
                    setData(null)
                    setIsLoading(false)
                    if(response.data.response && response.data.response==="no_results"){
                        setError('no_results'); 
                    }else{
                        setError('unknown_error'); 
                    }

                  
                  
                }
           
               
            })
            .catch(err=>{
                setError('network_err'); 
                setIsLoading(false)  
            })
            return () => cancel()
       
    
    
    },[])
 
    return {data, isLoading, error}
}

export default useFetch
