import React, {Component} from "react";
import {useState, useEffect} from 'react'
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';

import useFetch from '../APIs/FetchUsers.js';
import LoadingSkeleton from "../LoadingSkeletons/Card007.js";
import ConvertNum from '../ConvertNumbers/useConvertLargeNum.js';
const TopResults = (props) => {
   

      //userId,songId,search_string,genre,sort,page,
     

      const [data, setData] = useState(null);
      const [request, setRequest] = useState(null)
      const {data:FetchedData,isLoading, error} = useFetch(request)
  

      useEffect(()=>{
          if(FetchedData && FetchedData.total_results>0){
              setData(FetchedData)
          }
      },[FetchedData])
  
  
      const send_request =()=>{
          setData(null)
          setRequest({
              "user_id":"",
              "search_string":props.filters.search_string,
              "role":"",
              "sort":props.filters.sort,
              "page":"",
          })
      }
  
  
  
      useEffect(()=>{
          send_request()
      },[props.filters])

      const getImageColors = ()=>{
        
          
      }

      useEffect(()=>{
        if(data){
          getImageColors()
        }
    },[data])


  
  return (
    <>
    {data &&(
          <div className="col-12 col-lg-5">

            <section>
              <div className="htitle">
                  <div className="lft"><p>{props.filters.search_string !=="" || props.filters.sort !=="" ?<>TOP RESULTS</>:<>STREAMED ARTIST</>} <span className="platform">GENIUX MUSIC</span></p></div>
              </div>
              
              <div className="card007">
                  <div className="info">
                    <div className="lft">
                        <Link className="thumbnail" to={'/profile/'+data.results[0].user_id}>
                        {data.results[0].user_avatar.length>2?
                          <img src={"https://api.geniux.co.za/geniuxmusic/api/media/avatars/smaller/?file_name="+data.results[0].user_avatar}/>
                          :
                          <p>{data.results[0].artist_name.substring(0, 1)}</p>
                        }
                        </Link>
                        <div className="details">
                          <p><Link to={'/profile/'+data.results[0].user_id}>{data.results[0].artist_name}</Link></p>
                          <p><Link to={'/profile/'+data.results[0].user_id}>{data.results[0].interest}</Link></p>
                        </div>
                    </div>
                    <div className="rgt">
                    <Link className="thumbnail" to={'/profile/'+data.results[0].user_id}>
                        <Button variant="contained" color="primary">View Profile</Button>
                    </Link>
                       
                    </div>
                      
                      
                  </div>
                  <div className="stats">
                      <div className="column">
                        <p><ConvertNum num = {data.results[0].total_plays}/></p>
                        <p>Streams</p>
                      </div>
                      <div className="column"> 
                          <p><ConvertNum num = {data.results[0].total_likes}/></p>
                          <p>Likes</p>
                      </div>
                      <div className="column">
                        <p><ConvertNum num = {data.results[0].total_downloads}/></p>
                        <p>Downloads</p>
                      </div>
                  </div>
              </div> 
            </section>
        </div>  

      )}
      {isLoading &&(
        <div className="col-12 col-lg-5">
          <section>
              <div className="htitle">
                  <div className="lft"><p>{props.filters.search_string!==""?<>TOP RESULTS (0)</>:<>TOP PLAYED</>} <span className="platform">GENIUX MUSIC</span></p></div>
              </div>
              <LoadingSkeleton/>
          </section>
        </div>
       )}
    </>
  )
}

export default TopResults