import React, {useState, useEffect, useContext} from 'react';
import Button from '@mui/material/Button';
import { UserStoreContext } from '../Contexts/UserStoreContext';
import useSendComment from '../APIs/SendComment.js';
import CircularProgress from '@mui/material/CircularProgress';
import LogginDialog from '../Dialogs/LogginDialog.js';
import {useNavigate, useLocation} from 'react-router-dom';




export default function HideAppBar(props) {
  const navigate = useNavigate()
  const location = useLocation();

  const {is_logged_in, user_store} = useContext(UserStoreContext)
  const [message, setMessage] = useState('');
  const [showLogIn, setShowLogIn] = useState(false);

  const [isEmptyMessage, setIsEmptyMessage] = useState(null);


  const onhandle_message = (e) =>{
  
    setMessage(e.target.value)
   

  }


  const [data, setData] = useState(null);
  const [request,setRequest] = useState(null)
  const {data:FetchedData,isLoading, error} = useSendComment(request)

  useEffect(()=>{
    if(FetchedData && FetchedData.length>0){
      setMessage('')
      props.setData(null)
      props.setPage(1)
      props.send_request();
    }

  },[FetchedData])

  const send_request=()=>{
    setRequest({
      "song_id":props.song_id,
      "message": message,
    })
  }
  const onhandle_submit= (e) =>{
    e.preventDefault()
    if(is_logged_in){
      if(message===''){
        setIsEmptyMessage(true)
        setTimeout(function(){
            setIsEmptyMessage(false) 
        },1000);
      
      }else{
        send_request()
      }
    }else{
      setShowLogIn(true)
    }
    
  }



  return (
      
      <>
      
          <form noValidate autoComplete="off"  className="CommentBox">


                {is_logged_in?    
                    user_store.user_avatar.length>2?
                      <img className="ppimg" onClick={()=> navigate('/profile/'+user_store.user_id)} src={"https://api.geniux.co.za/geniuxmusic/api/media/avatars/smaller/?file_name="+user_store.user_avatar}/>
                    :
                    <div className="ppimg"  onClick={()=> navigate('/profile/'+user_store.user_id)}>{user_store.artist_name.substring(0, 1)}</div>  
                :
                    <div className="ppimg">ME</div>            
                }
                {location.pathname.includes("/spotify")?
                 <>
                  <Button variant="contained" color="primary" >Comment</Button>
                  <textarea placeholder="Comment disabled" readOnly={true} value={message}></textarea>
                 </>:
                  <>
                    <textarea placeholder="Type Comment" className={isEmptyMessage?'active':''} value={message} onChange={onhandle_message}></textarea>
                    <Button variant="contained" color="primary" onClick={onhandle_submit}>{error?"Failed, Retry":"Comment"} {isLoading && <CircularProgress/>}</Button>
                  </>
                }
                
               


          </form>
          {showLogIn &&(
            <LogginDialog setShowLogIn={setShowLogIn}/>
          )}
                
                  
      </>
  );
}
